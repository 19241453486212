import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Organisation, OrganisationParent, SubCategoryMaster } from '../../swagger/models';
import { useCombinedStore } from '../../store';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, NavLink } from 'react-router-dom';
import { organisationFormValidationSchema } from '../../helpers/validation';
import { CityType, StateType } from '../common-module/helper';

/**
 * Component for Market Participant Registration
 * 
 * This component renders a form for registering a Market Participant.
 * The form collects the following information:
 * - Organisation Name
 * - Registered Address
 * - State and City
 * - Pincode
 * - Exchange/Depository
 * - GST Number
 * - PAN Number
 * - Unique SEBI Id
 * - ISIN Number
 * - Incorporation Number
 * - Primary Contact Name
 * - Primary Contact Email
 * - Primary Contact Number
 * 
 * The form also displays a list of all the MII and the categories associated with each MII.
 * The user can select the MII and the categories they belong to.
 * The user can also select N/A as an option if they do not belong to any MII.
 * 
 * The component also renders a button to save the information.
 * When the button is clicked, the information is sent to the server and the user is redirected to the login page.
 * 
 * If the user has already registered, the component displays a message indicating that the user is already registered.
 * The user is also given the option to login.
 * 
 * The component also renders a message indicating that the user has already registered and the user is given the option to login.
 * 
 * The component is rendered only if the user is not logged in.
 * 
 * The component is rendered on the /intermediary route.
 */

function MarketParticipantRegistration() {
  const { t } = useTranslation();
  const [orgDetail, setOrgDetails] = useState<Organisation>({
    country: 'India',
    OrganisationParents: []
  })
  const [selectedMIIs, setSelectedMIIs] = useState<Organisation[]>([])

  const marketParticipantRegistration = useCombinedStore((state: any) => state.marketParticipantRegistration)
  const newMPCreated = useCombinedStore((state: any) => state.newMPCreated)

  const subCategoryMasters = useCombinedStore((state: any) => state.subCategoryMasters)
  const listSubCategoryMasters = useCombinedStore((state: any) => state.listSubCategoryMasters)
  const listMIIs = useCombinedStore((state: any) => state.listMIIs)
  const organisations: Organisation[] = useCombinedStore((state: any) => state.miis);

  const formOptions = {
    resolver: yupResolver(organisationFormValidationSchema),
  }

  const { register, handleSubmit, formState, reset } = useForm(formOptions)
  const { errors } = formState

  /**
   * 
   * @param e 
   */
  const onInputChange = (e: any) => {
    const { name, value } = e.target
    setOrgDetails({
      ...orgDetail,
      country: 'India',
      [name]: value,
      city: name === 'state' ? "" : ( name === 'city' ? value : orgDetail.city),
      organisationType: 'INTERMEDIARY',
    })
  }

  /**
   * 
   * @param e 
   * @returns 
   */
  async function onSubmit(e: any) {
    // e.preventDefault()
    marketParticipantRegistration(orgDetail)
    return false
  }

  const selectOrgParent = (e: any, orgId: number | null, subCategoryId: number) => {
    const orgParents = orgDetail.OrganisationParents || []
    if (e.checked) {
      // Add the Parent MII and selected category to the array list
      orgParents.push({
        subCategoryId: subCategoryId,
        parentId: orgId,
      })
      setOrgDetails({
        ...orgDetail,
        OrganisationParents: orgParents
      })
    } else {
      // Remove the Parent MII and selected category from Array list
      const index = orgParents.findIndex((p: OrganisationParent) => p.orgId === orgId)
      orgParents.splice(index, 1)
      setOrgDetails({
        ...orgDetail,
        OrganisationParents: orgParents
      })
    }
  }

  const selectMII = (e: any, orgId: number | null) => {
    const orgParents = selectedMIIs || []
    let selectedOrgParents = orgDetail.OrganisationParents || []
    if (e.checked) {
      // Add the Parent MII to the array list
      orgParents.push({
        orgId: orgId,
      })
      setSelectedMIIs(orgParents)
      selectedOrgParents.push({
        parentId: orgId,
      })
      setOrgDetails({
        ...orgDetail,
        OrganisationParents: selectedOrgParents
      })
    } else {
      const index = orgParents.findIndex((p: Organisation) => p.orgId === orgId)
      orgParents.splice(index, 1)

      selectedOrgParents = selectedOrgParents.filter((org: OrganisationParent) => org.orgId !== orgId);
      selectedOrgParents.splice(index, 1)
      // Remove the Parent MII from Array list
      setSelectedMIIs(orgParents)
      setOrgDetails({
        ...orgDetail,
        OrganisationParents: selectedOrgParents
      })
    }
  }

  useEffect(() => {
    listSubCategoryMasters()
    listMIIs();
  }, [])
  return (
    <div className="flex flex-row justify-between pt-7 px-10 lg:pl-32 pb-7 w-full h-fit">
      <div className="flex flex-col h-full w-full lg:w-1/3 pt-10 sm:pt-20 justify-between">
        <div className='flex flex-row justify-between items-start'>
          <NavLink to="login">
            <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/SMARTODR_LOGO.svg" alt="SMARTODR Logo" style={{ width: '175px', height: 'fit' }} />
          </NavLink>
          <div className='hidden lg:block'>
            <div className='grid grid-cols-2 gap-2'>
              <Link to="/mii/login" className="text-xs w-20 pt-2 text-highlight-blue hover:text-highlight-blue/50 whitespace-nowrap">{t('MII')} {t('login')}</Link>
              <Link to="/login" className="text-xs w-20 pt-2 text-highlight-blue hover:text-highlight-blue/50 whitespace-nowrap">{t('Investor')} {t('login')}</Link>
              <Link to="/intermediary/login" className="text-xs w-20 pt-2 text-highlight-blue hover:text-highlight-blue/50 whitespace-nowrap">{t('MarketParticipant')} {t('login')}</Link>
              <Link to="/odr/login" className="text-xs pt-2 w-20 text-highlight-blue hover:text-highlight-blue/50 whitespace-nowrap pr-7">{t('ODRInstitution')} {t('login')}</Link>
            </div>
          </div>
        </div>
        <div>
          <h1 className="pt-10 text-3xl text-highlight-blue font-bold">{t('MarketParticipantRegistration')}</h1>
          <p className="text-xs pt-3 pb-7">{t('AlreadyHaveAccount')} <Link to="/intermediary/login" style={{ color: '#306AFF' }}>{t('Loginhere')}</Link></p>
          {!newMPCreated ? (
            <div className="pb-8 border-b-2 border-highlight-blue">
              <div className="rounded-xl bg-light-blue flex flex-col gap-3 py-4 px-4 text-xs">
                <p className="font-bold text-highlight-blue">{t('ImportantNote')}</p>
                <p className="text-highlight-blue">{t('MPRegistrationNote')}</p>
                <p className="text-highlight-blue">{t('NewEntity')}</p>
              </div>
            </div>
          ) : (
            <div className="pb-8">
              <div className="rounded-xl bg-light-blue flex flex-col gap-3 py-4 px-4 text-xs">
                <div className="flex flex-row w-full h-fit gap-2 items-center">
                  <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/Green-Tick-Vector-PNG-Images.png" width={"30px"} />
                  <p className="font-bold text-highlight-blue">{t('AccountRegistrationSuccessful')}! {t('AccountPending')}</p>
                </div>
                <p className="text-highlight-blue">{t('MPAccountSuccess')}</p>
                <p className="text-highlight-blue">{t('VerificationTime')}</p>
                <p className="text-highlight-blue">{t('QueryHelp')}</p>
              </div>
            </div>
          )}
          {!newMPCreated && (
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col pt-8 gap-2" autoComplete="off">
              <label htmlFor="organisationName" className="text-xs">{t('NameOfMarketParticipant')}</label>
              <input disabled={orgDetail?.orgId} {...register('organisationName')} onChange={onInputChange} value={orgDetail?.organisationName} required aria-label={t('OrganisationName')} type="text" name='organisationName' id='organisationName' className={`rounded-xl border p-2 border-solid ${errors.organisationName?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.organisationName?.message}</span>

              <label htmlFor="registeredAddress" className="text-xs">{t('EnterRegisteredAddress')}</label>
              <input {...register('registeredAddress')} onChange={onInputChange} value={orgDetail?.registeredAddress} required aria-label={t('Address')} type="text" name='registeredAddress' id='registeredAddress' className={`rounded-xl border p-2 border-solid ${errors.registeredAddress?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.registeredAddress?.message}</span>
              <label htmlFor="State" className="text-xs">{t('State')}</label>
              <select
                aria-label={t('SelectState')} required name='state' id='state' onChange={onInputChange}
                className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={orgDetail?.state}
              >
                <option value="" disabled>{t('SelectState')}</option>
                {StateType?.map((state: string) => {
                  return <option key={state} value={state}>{state}</option>
                })}
              </select>
              <div className="flex flex-row justify-between">
                <div className="w-1/2 flex flex-col gap-2 pr-4">
                  <label htmlFor="City" className="text-xs">{t('City')}</label>
                  <select
                    aria-label={t('SelectCity')} {...register('city')} required name='city' id='city' onChange={onInputChange}
                    className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={orgDetail?.city}
                  >
                    <option value="" disabled>{t('Select City')}</option>
                    {CityType.filter((city: any) => city.state === orgDetail?.state)?.map((city: any) => {
                      return <option key={city} value={city.city}>{city.city}</option>
                    })}
                  </select>
                  <span className="text-xs text-highlight-red">{errors.city?.message}</span>

                </div>
                <div className="w-1/2 flex flex-col gap-2">
                  <label htmlFor="Pincode" className="text-xs">{t('Pincode')}</label>
                  <input {...register('pinCode')} onChange={onInputChange} value={orgDetail?.pinCode} required aria-label={t('Pincode')} type="text" name='pinCode' id='pinCode' className={`rounded-lg border p-2 border-solid ${errors.pinCode?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
                  <span className="text-xs text-highlight-red">{errors.pinCode?.message}</span>
                </div>
              </div>


              <div className="flex flex-row justify-between">
                <div className="flex flex-col gap-2 pr-4">
                  <p className="text-xs">{t('SelectExchangeDepository')} <span className="text-xs text-highlight-red">*</span></p>
                  {/* Show list of all the MII */}
                  {organisations?.map((organisation: Organisation, index: number) => {
                    return <div key={organisation?.orgId} className="flex flex-col gap-2">
                      <div className='flex gap-2 items-center'>
                        <input type="checkbox"
                          name={organisation?.orgId}
                          checked={selectedMIIs?.some(i => i.orgId === organisation.orgId)}
                          onChange={(e) => selectMII(e.target, organisation?.orgId)} />
                        <label htmlFor={organisation?.organisationName} className="text-xs">{organisation?.organisationName}</label>
                      </div>
                      {/* Show list of all the categories if MII is selected */}
                      {selectedMIIs?.length > 0 && selectedMIIs?.some(i => i.orgId === organisation.orgId) &&
                        <div className="pl-8 pb-2 flex flex-col gap-2 pr-4">
                          <p className="text-xs">{t('Category')} <span className="text-xs text-highlight-red">*</span></p>
                          {subCategoryMasters?.filter((category: SubCategoryMaster) => category.subCategoryId === 1 || category.subCategoryId === 4 || category.subCategoryId === 6)?.map((category: SubCategoryMaster, index: number) => {
                            return <div key={category?.subCategoryId} className="flex flex-row gap-2">
                              <input type="checkbox"
                                name={category?.subCategoryId}
                                checked={orgDetail?.OrganisationParents?.some((op: OrganisationParent) => op.parentId === organisation.orgId && op.subCategoryId === category?.subCategoryId)}
                                onChange={(e) => selectOrgParent(e.target, organisation?.orgId, category?.subCategoryId)} />
                              <label htmlFor={category?.categoryName} className="text-xs">{category?.categoryName}</label>
                            </div>
                          })}
                        </div>
                      }
                    </div>
                  })}
                  <div className="flex flex-col gap-2">
                  <div className='flex gap-2 items-center'>
                    <input type="checkbox"
                      name='N/A'
                      checked={selectedMIIs?.some(i => i.orgId === null)}
                      onChange={(e) => selectMII(e.target, null)} />
                    <label htmlFor='N/A' className="text-xs">N/A (Not Applicable)</label>
                    </div>
                    {/* Show list of all the categories if MII is selected */}
                    {selectedMIIs?.length > 0 && selectedMIIs?.some(i => i.orgId === null) &&
                      <div className="pl-8 pb-2 flex flex-col gap-2 pr-4">
                        <p className="text-xs">{t('Category')} <span className="text-xs text-highlight-red">*</span></p>
                        {subCategoryMasters?.filter((category: SubCategoryMaster) => category.subCategoryId !== 1 && category.subCategoryId !== 4 && category.subCategoryId !== 6)?.map((category: SubCategoryMaster, index: number) => {
                          return <div key={category?.subCategoryId} className="flex flex-row gap-2">
                            <input type="checkbox"
                              name={category?.subCategoryId}
                              checked={orgDetail?.OrganisationParents?.some((op: OrganisationParent) => op.parentId === null && op.subCategoryId === category?.subCategoryId)}
                              onChange={(e) => selectOrgParent(e.target, null, category?.subCategoryId)} />
                            <label htmlFor={category?.categoryName} className="text-xs">{category?.categoryName}</label>
                          </div>
                        })}
                      </div>
                    }
                  </div>
                </div>
              </div>

              <label htmlFor="GST" className="text-xs">{t('GSTNumber')}</label>
              <input {...register('gstNumber')} onChange={onInputChange} value={orgDetail?.gstNumber} aria-label={t('GSTNumber')} type="text" name='gstNumber' id='gstNumber' className={`rounded-xl border p-2 border-solid ${errors.gstNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.gstNumber?.message}</span>

              <label htmlFor="PAN" className="text-xs">{t("PANNumber")}</label>
              <input {...register('panNumber')} onChange={onInputChange} value={orgDetail?.panNumber} aria-label={t("PANNumber")} type="text" name='panNumber' id='panNumber' className={`rounded-xl border p-2 border-solid ${errors.panNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.panNumber?.message}</span>

              <label htmlFor="uniqueSEBIId" className="text-xs">{t("UniqueSEBIId")}</label>
              <input {...register('uniqueSEBIId')} onChange={onInputChange} value={orgDetail?.uniqueSEBIId} aria-label={t("UniqueSEBIId")} type="text" name='uniqueSEBIId' id='uniqueSEBIId' className={`rounded-xl border p-2 border-solid ${errors.uniqueSEBIId?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.uniqueSEBIId?.message}</span>

              <label htmlFor="ISINNumber" className="text-xs">{t('ISINNumber')}</label>
              <input {...register('ISINNumber')} onChange={onInputChange} value={orgDetail?.ISINNumber} aria-label={t('ISINNumber')} type="text" name='ISINNumber' id='ISINNumber' className={`rounded-xl border p-2 border-solid ${errors.ISINNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.ISINNumber?.message}</span>

              <label htmlFor="incorporationNumber" className="text-xs">{t("InCorporationNumber")}</label>
              <input {...register('incorporationNumber')} onChange={onInputChange} value={orgDetail?.incorporationNumber} aria-label={t("InCorporationNumber")} type="text" name='incorporationNumber' id='incorporationNumber' className={`rounded-xl border p-2 border-solid ${errors.incorporationNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.incorporationNumber?.message}</span>

              <label htmlFor="primaryContactName" className="text-xs">{t('EnterPrimaryName')}</label>
              <input {...register('primaryContactName')} onChange={onInputChange} value={orgDetail?.primaryContactName} required aria-label={t('EnterPrimaryName')} type="text" name='primaryContactName' id='primaryContactName' className={`rounded-xl border p-2 border-solid ${errors.primaryContactName?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.primaryContactName?.message}</span>

              <label htmlFor="primaryEmail" className="text-xs">{t('EnterPrimaryEmail')}</label>
              <input disabled={orgDetail?.orgId} {...register('primaryEmail')} onChange={onInputChange} value={orgDetail?.primaryEmail} required aria-label={t('EnterPrimaryEmail')} type="email" name='primaryEmail' id='primaryEmail' className={`rounded-xl border p-2 border-solid ${errors.primaryEmail?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.primaryEmail?.message}</span>

              <label htmlFor="primaryContact" className="text-xs">{t('EnterPrimaryContact')}</label>
              <input {...register('primaryContact')} onChange={onInputChange} value={orgDetail?.primaryContact} required aria-label={t('EnterPrimaryContact')} type="text" name='primaryContact' id='primaryContact' className={`rounded-xl border p-2 border-solid ${errors.primaryContact?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors.primaryContact?.message}</span>

              <button type='submit' className="bg-highlight-blue disabled:bg-highlight-blue/40 hover:bg-highlight-blue/[0.8] mt-4 text-white text-sm py-4 px-28 rounded-lg" aria-label={t('Save')}>
                {t('Proceed')}
              </button>
            </form>
          )}
        </div>
      </div>
      <div className='hidden lg:flex w-1/2'>
        <div className='hidden lg:block w-fit h-full px-10 pr-20 mb-8 py-8 fixed top-0 right-0 rounded-2xl'>
          <img src="https://ocp-module-development.s3.ap-south-1.amazonaws.com/HeroIMAGESMARTODR.png"
            className='w-fit overflow-hidden h-full rounded-xl' alt="hero" />
        </div>
      </div>
    </div>
  )
}

export default MarketParticipantRegistration