import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiController } from "../../../apiController";
import { User, UserParent } from "../../../swagger/models";
import _ from "lodash";
import { notifyError } from "../../../helpers/helper";
import { useCombinedStore } from "../../../store";
import { useTranslation } from 'react-i18next';
import { Tooltip } from "react-tippy";

/**
 * The Settings component is a page that displays the settings for the logged in user.
 * It displays the user's current profile, and allows the user to switch to another profile.
 * If the user is an intermediary, it also displays the list of MII profiles that the user is linked to,
 * and allows the user to switch to another MII profile.
 * If the user is an intermediary and has multiple MII profiles, it also displays the list of categories
 * that the user is linked to, and allows the user to filter the disputes history by category.
 */
function Settings() {
  const { t } = useTranslation()
  const [userParents, setUserParents] = useState<any[]>([]);
  const [categoryParents, setCategoryParents] = useState<any[]>([]);
  const [currentProfile, setCurrentProfile] = useState<any>({});
  const [currentUserProfile, setCurrentUserProfile] = useState<any>({});
  const currentURL = window.location.href.split("/")[3];
  const navigate = useNavigate();
  const loadAllOrgProfiles = useCombinedStore(
    (state: any) => state.loadAllOrgProfiles
  );
  const orgProfiles = useCombinedStore((state: any) => state.orgProfiles);

  const redirect = () => {
    if (currentURL === "mii" || currentURL === "admin") {
      navigate("organisation-management");
    } else if (currentURL === "intermediary" || currentURL === "odr") {
      navigate("user-management");
    }
  };

  useEffect(() => {
    loadAllOrgProfiles();
  }, []);

  /**
   *
   */
  useEffect(() => {
    const navSubscription = ApiController.getInstance().UserLoggedIn$.subscribe((user: any) => {
      if (user) {
        setCurrentUserProfile(user);
        const userParents = JSON.parse(
          localStorage.getItem("selectMIIProfiles")!
        );
        const unqiueIds = _.uniqBy(userParents, "parentId");
        setUserParents(unqiueIds);
        const unqiueCatIds = _.uniqBy(userParents, "subCategoryId");
        setCategoryParents(unqiueCatIds);
        // setCurrentProfile(JSON.parse(localStorage.getItem("selectProfile")!));
      }
    });
    return () => {
      navSubscription.unsubscribe()
    }
  }, [])

  const refreshProfile = async (parentId: number, index: number) => {
    try {
      const data = (await ApiController.getInstance().authApi.switchIntermediaryProfile({
        parentId,
      })).data
      ApiController.getInstance().initApis(data.token);
      // localStorage.setItem("selectProfile", JSON.stringify(userParents[index]));
      window.location.reload();
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  };

  const changeOrganisation = async (orgId: number, index: number) => {
    try {
      const data = (await ApiController.getInstance().authApi.switchOrganisationProfile({
        orgId,
      })).data
      if (data.UserParents) {
        localStorage.setItem(
          "selectMIIProfiles",
          JSON.stringify(data.UserParents)
        );
        // localStorage.setItem(
        //   "selectProfile",
        //   JSON.stringify(data.UserParents[0])
        // );
      }
      ApiController.getInstance().initApis(data.token);
      ApiController.getInstance().updateLoggedInStatus(data);
      window.location.reload();
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  };
  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: "#F4F4F4" }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl"
      >
        <p className="sm:block hidden text-xs text-back-gray">{t('UserSettings')}</p>
        <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue pb-3">
          {t('UserSettings')}
        </h1>
        <div className="bg-white w-full flex flex-col rounded-xl gap-4">
          <p className="py-3 border-b border-F5F5F5 text-xs pl-7"> </p>
          {currentURL !== "investor" && currentURL !== "admin" && (
            <Link
              to={`organisation-settings/${currentUserProfile?.orgId ||
                currentUserProfile?.Organisation?.orgId
                }`}
              className="flex flex-row pb-4 items-center justify-between px-7 border-b border-F5F5F5"
            >
              <div className="flex flex-row gap-3">
                <i className="ph-bold ph-user-list sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                <div className="flex flex-col sm:gap-2 gap-1">
                  <p className="sm:text-sm text-xs">{t('AccountSettings')}</p>
                  <p className="sm:text-xs text-[10px] text-back-gray">{t('CompanyInformation')}</p>
                </div>
              </div>
              <i className="ph-bold ph-caret-right"></i>
            </Link>
          )}
          <Link
            to="account-settings"
            className="flex flex-row pb-4 items-center justify-between px-7 border-b border-F5F5F5"
          >
            <div className="flex flex-row gap-3">
              <i className="ph-bold ph-user-circle-plus sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
              <div className="flex flex-col sm:gap-2 gap-1">
                <p className="sm:text-sm text-xs">{t('UserSettings')}</p>
                <p className="sm:text-xs text-[10px] text-back-gray">{t('PersonalInformation')}</p>
              </div>
            </div>
            <i className="ph-bold ph-caret-right"></i>
          </Link>
          <Link to="security" className="flex flex-row pb-4 items-center justify-between px-7 border-b border-F5F5F5">
            <div className="flex flex-row gap-3">
              <i className="ph-bold ph-key sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
              <div className="flex flex-col sm:gap-2 gap-1">
                <p className="sm:text-sm text-xs">{t('Security')}</p>
                <p className="sm:text-xs text-[10px] text-back-gray">{t('ChangePassword')}</p>
              </div>
            </div>
            <i className="ph-bold ph-caret-right"></i>
          </Link>
          {currentURL !== "investor" && (
            <div
              onClick={redirect}
              className="flex cursor-pointer flex-row pb-4 items-center justify-between px-7 border-b border-F5F5F5"
            >
              <div className="flex flex-row gap-3">
                <i className="ph-bold ph-address-book sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                <div className="flex flex-col sm:gap-2 gap-1">
                  <p className="sm:text-sm text-xs">{t('UserManagement')}</p>
                  <p className="sm:text-xs text-[10px] text-back-gray">{t('AdditionalUsers')}</p>
                </div>
              </div>
              <i className="ph-bold ph-caret-right"></i>
            </div>
          )}
          {currentURL !== 'admin' &&
            <Link to="notification-settings" className="flex flex-row pb-4 items-center justify-between px-7 border-b border-F5F5F5">
              <div className="flex flex-row gap-3">
                <i className="ph-bold ph-bell sm:p-3 p-2 border h-fit border-F5F5F5 rounded-full"></i>
                <div className="flex flex-col sm:gap-2 gap-1">
                  <p className="sm:text-sm text-xs">{t('Notifications')}</p>
                  <p className="sm:text-xs text-[10px] text-back-gray">{t('EnableOrDisable')}</p>
                </div>
              </div>
              <i className="ph-bold ph-caret-right"></i>
            </Link>
          }
          {currentURL === 'odr' &&
            <Link to="ODR-Infobank" className="flex flex-row pb-4 items-center justify-between px-7 border-b border-F5F5F5">
              <div className="flex flex-row gap-3">
                <i className="ph-bold ph-buildings sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                <div className="flex flex-col sm:gap-2 gap-1">
                  <p className="sm:text-sm text-xs">{t('ODRInstitutionInfobank')}</p>
                  <p className="sm:text-xs text-[10px] text-back-gray">
                    {t('EdityourData')}
                  </p>
                </div>
                <i className="ph-bold ph-caret-right"></i>
              </div>
            </Link>
          }
          {currentURL === "admin" && (
            <Link
              to="duplicate-account-settings"
              className="flex flex-row pb-4 items-center justify-between px-7 border-b border-F5F5F5"
            >
              <div className="flex flex-row gap-3">
                <i className="ph-bold ph-bell sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                <div className="flex flex-col sm:gap-2 gap-1">
                  <p className="sm:text-sm text-xs">
                    Manage Duplicate Accounts
                  </p>
                  <p className="sm:text-xs text-[10px] text-back-gray">
                    Enable or Disable Notifications
                  </p>
                </div>
              </div>
              <i className="ph-bold ph-caret-right"></i>
            </Link>
          )}
          {currentURL === 'odr' &&
            <Link to="ODR-Api" className="flex flex-row pb-4 items-center justify-between px-7 border-b border-F5F5F5">
              <div className="flex flex-row gap-3">
                <i className="ph-bold ph-buildings sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
                <div className="flex flex-col sm:gap-2 gap-1">
                  <p className="sm:text-sm text-xs">{t('GenerateAPIKey')}</p>
                  <p className="sm:text-xs text-[10px] text-back-gray">{t("EdityourData")}</p>
                </div>
              </div>
              <i className="ph-bold ph-caret-right"></i>
            </Link>
          }
          <p className="pb-3 text-xs pl-7">{" "}</p>
        </div>
        {currentURL === 'intermediary' &&
          <div className="flex flex-col justify-end h-full gap-3 pb-7">
            <p className="sm:text-3xl text-lg font-semibold text-highlight-blue py-3">{t('SelectTheOrgDisputes')}</p>
            {orgProfiles?.filter((userProfile: User) => userProfile.orgId).length > 0 &&
              <div className="bg-white px-6 py-6 rounded-lg">
                <div className="overflow-hidden rounded-lg border border-org-border text-sm">
                  <table className="min-w-full">
                    <thead>
                      <tr className="text-sm font-medium text-gray-500 bg-org-header">
                        <th className="px-4 py-2 text-left w-4/12">{t('OrganisationName')}</th>
                        <th className="px-4 py-2 text-left w-4/12">{t('RegistrationNumber')}</th>
                        <th className="px-4 py-2 text-left w-3/12">{t('PendingDisputesCount')}</th>
                        <th className="px-4 py-2 w-1/12"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {orgProfiles.map((profile: any, index: number) => (
                        <tr
                          key={index}
                          onClick={() => changeOrganisation(profile.orgId, index)}
                          className="hover:bg-gray-50 cursor-pointer border-b border-org-border"
                        >
                          <td className="px-4 py-3">
                            <span className="font-medium text-gray-900">
                              {profile.Organisation.organisationName}
                              {currentUserProfile.orgId === profile.orgId ? <span className="ml-2 text-gray-500 italic text-sm">(current session)</span> : ""}
                            </span>
                          </td>
                          <td className="px-4 py-3 text-gray-600">
                            {profile.Organisation.uniqueSEBIId}
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex gap-2">
                              <Tooltip
                                html={
                                  <div className=" text-left flex flex-col gap-1">
                                    <p className="">{t('PendingDisputesPreCon')}</p>
                                  </div>
                                }
                                position="top"
                                arrow={false}
                                animation="shift"
                                size="small"
                                {...({ children: <i className="ph text-status-green border-status-green border ph-plus h-fit text-xs p-0.5" style={{ borderRadius: '50%' }}></i> } as any)} // Type assertion to bypass TS errors
                              >
                                <span className="px-2 py-1 bg-org-orange text-white rounded-full text-xs">
                                  P: {profile.Organisation.preconciliationPending}
                                </span>
                              </Tooltip>
                              <Tooltip
                                html={
                                  <div className=" text-left flex flex-col gap-1">
                                    <p className="">{t('PendingDisputesCon')}</p>
                                  </div>
                                }
                                position="top"
                                arrow={false}
                                animation="shift"
                                size="small"
                                {...({ children: <i className="ph text-status-green border-status-green border ph-plus h-fit text-xs p-0.5" style={{ borderRadius: '50%' }}></i> } as any)} // Type assertion to bypass TS errors
                              >
                                <span className="px-2 py-1 bg-org-green text-white rounded-full text-xs">
                                  C: {profile.Organisation.conciliationPending}
                                </span>
                              </Tooltip>
                              <Tooltip
                                html={
                                  <div className=" text-left flex flex-col gap-1">
                                    <p className="">{t('PendingDisputesArb')}</p>
                                  </div>
                                }
                                position="top"
                                arrow={false}
                                animation="shift"
                                size="small"
                                {...({ children: <i className="ph text-status-green border-status-green border ph-plus h-fit text-xs p-0.5" style={{ borderRadius: '50%' }}></i> } as any)} // Type assertion to bypass TS errors
                              >
                                <span className="px-2 py-1 bg-org-purple text-white rounded-full text-xs">
                                  A: {profile.Organisation.arbitrationPending}
                                </span>
                              </Tooltip>

                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex justify-end">
                              <i className="ph ph-caret-right w-5 h-5 text-gray-400" />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            }

            {/* {userParents?.filter((userParent: UserParent) => userParent.parentId).length > 0 &&
              <>
                <p className="sm:text-3xl text-lg font-semibold text-highlight-blue py-3">{t('SwitchMII')}</p>
                <div className="bg-white w-full flex flex-col rounded-xl gap-4">
                  <p className="py-3 border-b border-F5F5F5 text-xs pl-7"> </p>
                  <div className="flex flex-col gap-3 px-7">
                    <p className="sm:text-sm text-xs">{t('CurrentlyLoggedWith', {
                      mii: currentProfile?.Parent?.organisationName
                    })}</p>
                    <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-8 gap-2">
                      {userParents?.map(
                        (userParent: UserParent, index: number) => {
                          return (
                            <div
                              key={userProfile?.orgId}
                              onClick={() =>
                                changeOrganisation(userProfile.orgId, index)
                              }
                              className="p-4 w-fit hover:bg-highlight-blue hover:text-white cursor-pointer rounded-lg bg-light-blue text-highlight-blue text-sm"
                            >
                              <p>{userProfile?.Organisation?.organisationName}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <p className="py-3 border-t border-F5F5F5 text-xs pl-7"> </p>
                  </>
                </div>
              )} */}
            {/* {userParents?.filter(
              (userParent: UserParent) => userParent.parentId
            ).length > 0 && (
                <>
                  <p className="sm:text-3xl text-lg font-semibold text-highlight-blue py-3">
                    {t('SwitchMII')}
                  </p>
                  <div className="bg-white w-full flex flex-col rounded-xl gap-4">
                    <p className="py-3 border-b border-F5F5F5 text-xs pl-7"> </p>
                    <div className="flex flex-col gap-3 px-7">
                      <p className="sm:text-sm text-xs">
                        {t('CurrentlyLoggedWith', {
                          mii: currentProfile?.Parent?.organisationName
                        })}
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-8 gap-2">
                        {userParents?.map(
                          (userParent: UserParent, index: number) => {
                            return (
                              <div
                                key={userParent?.parentId}
                                onClick={() =>
                                  refreshProfile(userParent.parentId, index)
                                }
                                className="p-4 w-fit hover:bg-highlight-blue hover:text-white cursor-pointer rounded-lg bg-light-blue text-highlight-blue text-sm"
                              >
                                <p>
                                  {userParent?.Parent?.organisationName.length > 1
                                    ? userParent?.Parent?.organisationName
                                    : "Other"}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <p className="py-3 border-t border-F5F5F5 text-xs pl-7"> </p>
                  </div>
                </>
              )} */}
            {
              categoryParents?.filter((userParent: UserParent) => userParent.subCategoryId).length > 0 &&
              <>
                <div className="flex flex-row flex-wrap gap-1 text-listed-companies px-7 items-center">
                  <p className="sm:text-sm text-xs">{t('AccountLinkedCategories')} -</p>
                  {categoryParents?.map((userParent: UserParent, index: number) => {
                    return (userParent?.SubCategoryMaster && <p className="sm:text-sm text-xs">{userParent?.SubCategoryMaster?.categoryName}
                      {console.log(categoryParents?.filter((userParent: UserParent) => userParent.subCategoryId).length > 1)}
                      {index === categoryParents.length - 1 ? '.' : ','}
                    </p>
                    )
                  })}
                </div>
                <p className="py-3 border-t border-F5F5F5 text-xs pl-7">{" "}</p>
              </>
            }
          </div>
        }
      </div>
    </div>
  );
}

export default Settings;
