import * as Yup from 'yup'

export const loginFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid')
  // .matches(
  //   /^[a-z0-9!#$%&‘*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  // )
  ,
  password: Yup.string()
    // .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must not be more than 32 characters')
    .required('Password is required'),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,32}$/g, "Password must be at least 8 characters, maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
  // ),
  otp: Yup.string()
    .length(6, 'OTP must be 6 digits')
    .matches(/^\d+$/, 'Only numbers are allowed for this field ')
})

export const otpFormValidationSchema = Yup.object().shape({
  phoneNo: Yup.string()
    .matches(/^\d+$/, 'Only numbers are allowed for this field ')
    .notOneOf(['0000000000', null, '9999999999', '1111111111', '7897897897', '9898989898'], 'Use valid phone number')
    .min(10, 'Phone No. must be at least 10 digits')
    .max(10, 'Phone No. must not be more than 10 digits')
    .required('Phone No. is required'),
})

export const forgetPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    // .matches(
    //   /^[a-z0-9!#$%&‘*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    // )
    .email('Email is invalid'),
})

export const changePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .notOneOf(['123456', null, 'password', 'abcdef'], 'Use strong password')
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must not be more than 32 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,32}$/g, "Password must be at least 8 characters, maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
  confirmPassword: Yup.string()
    // .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must not be more than 32 characters')
    .required('Confirm Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,32}$/g, "Password must be at least 8 characters, maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
})

export const internalChangePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must not be more than 32 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,32}$/g, "Password must be at least 8 characters, maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
  newPassword: Yup.string()
    .notOneOf([Yup.ref('oldPassword'), '123456', null, 'password', 'abcdef'], 'Old Password and new password should be different')
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must not be more than 32 characters')
    .required('New Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,32}$/g, "Password must be at least 8 characters, maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
  confPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'New Password and Confirm password should be different')
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must not be more than 32 characters')
    .required('Confirm Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,32}$/g, "Password must be at least 8 characters, maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
})

export const organisationFormValidationSchema = Yup.object().shape({
  primaryEmail: Yup.string().required('Email is required')
  .matches(
    /^[a-zA-Z0-9.&_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    'Email is invalid'
  )
  .email('Email is invalid'),
  primaryContactName: Yup.string().required('Primary Contact Name is required')
  .matches(/^[A-Za-z\s-.)&(\[\]]+$/, 'Only alphabets, space and dot are allowed for this field.')
  .min(3, 'Name must be at least 3 characters'),
  organisationName: Yup.string()
    .required('Name is required')
    .matches(/^[A-Za-z0-9\s-._)&#(\[\]]+$/, 'Only alphabets, space and dot are allowed for this field.')
    .min(2, 'Name must be at least 2 characters'),
  primaryContact: Yup.string()
    .required('Phone number is required')
    .notOneOf(['0000000000', null, '9999999999', '1111111111', '7897897897', '9898989898'], 'Use valid phone number')
    .matches(/^\d+$/, 'Only numbers are allowed for this field ')
    .min(10, 'Phone no. must be at least 10 digits')
    .max(10, 'Phone no. must not be more than 10 digits'),
  registeredAddress: Yup.string()
    .required('Address is required')
    .min(2, 'Address must be at least 2 characters'),
  city: Yup.string()
    .required('City is required')
    .matches(/^[A-Za-z0-9\s-)(]+$/, 'Only alpha numerics are allowed for this field ')
    .min(2, 'City must be at least 2 characters'),
  pinCode: Yup.string()
    .required('Pincode is required')
    .matches(/^\d+$/, 'Only numbers are allowed for this field ')
    .min(6, 'Pincode must be at least 6 characters')
    .max(6, 'Pincode must not be more than 6 characters'),
  uniqueSEBIId: Yup.string()
    .when('$isUniqueSEBIIdVisible', {
      is: true, // Apply validation only if the field is visible
      then: (schema) =>
        schema.nullable(),
      otherwise: (schema) => schema.notRequired(),
    }),
  incorporationNumber: Yup.string()
    .when('$isIncorporationNumberVisible', {
      is: true, // Apply validation only if the field is visible
      then: (schema) =>
        schema.nullable(),
      otherwise: (schema) => schema.notRequired(),
    }),
  ISINNumber: Yup.string()
    .when('$isISINNumberVisible', {
      is: true, // Apply validation only if the field is visible
      then: (schema) =>
        schema.nullable(),
      otherwise: (schema) => schema.notRequired(),
    }),
  panNumber: Yup.string()
    .when('panNumber', {
      is: (val: any) => val && val.trim(), // Only validate if there's a value
      then: () =>
        Yup.string()
          .matches(/[A-Z]{5}\d{4}[A-Z]/, 'Please ensure PAN format is First 5 Alphabets, next 4 digits, then 1 Alphabet')
          .min(10, 'PAN must be at least 10 characters')
          .max(10, 'PAN must not be more than 10 characters'),
    }),
  gstNumber: Yup.string()
    .when('gstNumber', {
      is: (val: any) => val && val.trim(), // Only validate if there's a value
      then: () =>
        Yup.string()
          .matches(/\d{2}[A-Z]{5}\d{4}[A-Z][A-Z\d]Z[A-Z\d]/, 'Please ensure GST format is First 2 digits, next 5 Alphabets, next 4 digits, then 1 Alphabet, then 1 alphabet or number, then Z, then 1 alphabet or number.')
          .min(15, 'GST must be at least 15 characters')
          .max(15, 'GST must not be more than 15 characters'),
    }),
}, [
  ['gstNumber', 'gstNumber'],
  ['panNumber', 'panNumber'],
])

export const userFormValidationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  userName: Yup.string()
    .required('Name is required')
    .matches(/^[A-Za-z\s.]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'Name must be at least 2 characters'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .notOneOf(['0000000000', null, '9999999999', '1111111111', '7897897897', '9898989898'], 'Use valid phone number')
    .matches(/^\d+$/, 'Only numbers are allowed for this field ')
    .min(10, 'Phone no. must be at least 10 digits')
    .max(10, 'Phone no. must not be more than 10 digits'),
  address: Yup.string()
    .required('Address is required')
    .min(2, 'Address must be at least 2 characters'),
  city: Yup.string()
    .required('City is required')
    .matches(/^[A-Za-z0-9\s-)(]+$/, 'Only alpha numerics are allowed for this field ')
    .min(2, 'City must be at least 2 characters'),
  pinCode: Yup.string()
    .required('Pincode is required')
    .matches(/^\d+$/, 'Only numbers are allowed for this field ')
    .min(6, 'Pincode must be at least 6 characters')
    .max(6, 'Pincode must not be more than 6 characters'),
  panNumber: Yup.string()
    // .required('PAN is required')
    .matches(/[A-Z]{5}\d{4}[A-Z]/, 'Please ensure PAN format is First 5 Alphabets, next 4 digits, then 1 Alphabet')
    .min(10, 'PAN must be at least 10 characters')
    .max(10, 'PAN must not be more than 10 characters'),
})

export const ODRFormValidationSchema = Yup.object().shape({
  totalConciliators: Yup.number().required('Total Mediators is required'),
  totalArbitrators: Yup.number()
    .required('Total Arbitrators is required')
    .min(1, 'Total Arbitrators must be at least 1'),
  empanelmentUrl: Yup.string()
    .required('Empanel ODR URL is required')
    .url('Invalid URL format'),
  grievanceUrl: Yup.string()
    .required('Grievance URL is required')
    .url('Invalid URL format'),
  rulesUrl: Yup.string()
    .required('Rules URL is required')
    .url('Invalid URL format'),
});

export const registrationValidation = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  userName: Yup.string()
    .required('Name is required')
    .matches(/^[A-Za-z\s.]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'Name must be at least 2 characters'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .notOneOf(['0000000000', null, '9999999999', '1111111111', '7897897897', '9898989898'], 'Use valid phone number')
    .matches(/^\d+$/, 'Only numbers are allowed for this field ')
    .min(10, 'Phone no. must be at least 10 digits')
    .max(10, 'Phone no. must not be more than 10 digits'),
  address: Yup.string()
    .required('Address is required')
    .min(2, 'Address must be at least 2 characters'),
  city: Yup.string()
    .required('City is required')
    .matches(/^[A-Za-z0-9\s-)(]+$/, 'Only alpha numerics are allowed for this field ')
    .min(2, 'City must be at least 2 characters'),
  pinCode: Yup.string()
    .required('Pincode is required')
    .notOneOf(['000000', '111111', null], 'Use correct pincode')
    .matches(/^\d+$/, 'Only numbers are allowed for this field ')
    .min(6, 'Pincode must be at least 6 characters')
    .max(6, 'Pincode must not be more than 6 characters'),
  panNumber: Yup.string()
    // .required('PAN is required')
    .matches(/[A-Z]{5}\d{4}[A-Z]/, 'Please ensure PAN format is First 5 Alphabets, next 4 digits, then 1 Alphabet')
    .min(10, 'PAN must be at least 10 characters')
    .max(10, 'PAN must not be more than 10 characters'),
  password: Yup.string()
    .notOneOf(['123456', null, 'password', 'abcdef'], 'Use strong password')
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must not be more than 32 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,32}$/g, "Password must be at least 8 characters, maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'New Password and Confirm password should be same')
    .min(8, 'Password must be at least 8 characters')
    .max(32, 'Password must not be more than 32 characters')
    .required('Confirm Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,32}$/g, "Password must be at least 8 characters, maximum 32 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
})

export const scoresValidation = Yup.object().shape({
  primaryCaseRefId: Yup.string()
    .required('Primary Case Ref Id is required')
    .matches(/^[A-Za-z0-9\s-_/\/]+$/, 'Enter valid primary case ref Id')
    .min(2, 'Case Id must be at least 2 characters'),
})

export const newUserValidationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  userName: Yup.string()
    .required('Name is required')
    .matches(/^[A-Za-z\s.]+$/, 'Only alphabets are allowed for this field ')
    .min(2, 'Name must be at least 2 characters'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .notOneOf(['0000000000', null, '9999999999', '1111111111', '7897897897', '9898989898'], 'Use valid phone number')
    .matches(/^\d+$/, 'Only numbers are allowed for this field ')
    .min(10, 'Phone no. must be at least 10 digits')
    .max(10, 'Phone no. must not be more than 10 digits'),
  panNumber: Yup
    .string()
    .nullable()
    .notRequired()
    .when('panNumber', {
      is: (value: string) => value?.length,
      then: (rule) => rule.min(10, 'PAN must be at least 10 characters')
        .max(10, 'PAN must not be more than 10 characters')
        .matches(/[A-Z]{5}\d{4}[A-Z]/, 'Please ensure PAN format is First 5 Alphabets, next 4 digits, then 1 Alphabet')
    }),
  address: Yup
    .string()
    .nullable()
    .notRequired()
    .when('address', {
      is: (value: string) => value?.length,
      then: (rule) => rule.min(2, 'Address must be at least 2 characters')
    }),
  pinCode: Yup
    .string()
    .nullable()
    .notRequired()
    .when('pinCode', {
      is: (value: string) => value?.length,
      then: (rule) => rule.matches(/^\d+$/, 'Only numbers are allowed for this field ')
        .min(6, 'Pincode must be at least 6 characters')
        .max(6, 'Pincode must not be more than 6 characters')
    }),
  city: Yup
    .string()
    .nullable()
    .notRequired()
    .when('city', {
      is: (value: string) => value?.length,
      then: (rule) => rule.matches(/^[A-Za-z0-9\s-)(]+$/, 'Only alpha numerics are allowed for this field ')
        .min(2, 'City must be at least 2 characters')
    }),
}, [
  // Add Cyclic deps here because when require itself
  ['city', 'city'],
  ['pinCode', 'pinCode'],
  ['address', 'address'],
  ['panNumber', 'panNumber'],
])