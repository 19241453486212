import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiController } from "../../apiController";
import { useCombinedStore } from "../../store";
import { User } from "../../swagger/models";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { notifyError } from "../../helpers/helper";
import { Tooltip } from "react-tippy";

function IntermediaryMultipleOrgSelection() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const orgProfiles = useCombinedStore((state: any) => state.orgProfiles);
  const [currentUserProfile, setCurrentUserProfile] = useState<User>({});
  const loadAllOrgProfiles = useCombinedStore(
    (state: any) => state.loadAllOrgProfiles
  );

  useEffect(() => {
    loadAllOrgProfiles();
    ApiController.getInstance().UserLoggedIn$.subscribe(
      (userLoggedIn: User | null) => {
        if (userLoggedIn) {
          setCurrentUserProfile(userLoggedIn);
          if (userLoggedIn.userType !== "Intermediary") {
            navigate(`/intermediary/login`);
          }
        }
      }
    );
  }, []);

  const changeOrganisation = async (orgId: number, index: number) => {
    try {
      const data = (
        await ApiController.getInstance().authApi.switchOrganisationProfile({
          orgId,
        })
      ).data;
      localStorage.setItem('SELECTED MP PROFILE', JSON.stringify(data))
      ApiController.getInstance().initApis(data.token);
      ApiController.getInstance().updateLoggedInStatus(data);
      navigate(`/intermediary/disputes`);
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (
      orgProfiles?.filter((userProfile: User) => userProfile.orgId).length ===
      1 &&
      currentUserProfile.userType === "Intermediary"
    ) {
      console.log("Inside multiple org selection component org profile 1 count");
      changeOrganisation(orgProfiles[0].orgId, 0);
    }
  }, [orgProfiles]);

  return (
    <div className="w-full min-h-screen bg-org-bg p-8 flex justify-center items-center">
      <div className="max-w-4xl bg-white rounded-xl shadow-sm max-h-96 min-h-96 overflow-auto">
        <div className="p-6">
          <h1 className="text-2xl font-semibold text-gray-900 mb-2 text-org-blue">{t('SelectTheOrgDisputes')}</h1>
          <p className="text-sm text-gray-500 mb-6">
            {t('MultipleAccountLinked')}
          </p>
          <div className="overflow-hidden rounded-lg border border-org-border text-sm">
            <table className="min-w-full">
              <thead>
                <tr className="text-sm font-medium text-gray-500 bg-org-header">
                  <th className="px-4 py-2 text-left w-4/12">{t('OrganisationName')}</th>
                  <th className="px-4 py-2 text-left w-4/12">{t('RegistrationNumber')}</th>
                  <th className="px-4 py-2 text-left w-3/12">{t('PendingDisputesCount')}</th>
                  <th className="px-4 py-2 w-1/12"></th>
                </tr>
              </thead>
              <tbody>
                {orgProfiles.map((profile: any, index: number) => (
                  <tr
                    key={index}
                    onClick={() => changeOrganisation(profile.orgId, index)}
                    className="hover:bg-gray-50 cursor-pointer border-b border-org-border"
                  >
                    <td className="px-4 py-3">
                      <span className="font-medium text-gray-900">
                        {profile.Organisation.organisationName}
                        {profile.isCurrentSession && (
                          <span className="ml-2 text-gray-500 italic text-sm">({t('currentsession')})</span>
                        )}
                      </span>
                    </td>
                    <td className="px-4 py-3 text-gray-600">
                      {profile.Organisation.uniqueSEBIId}
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex gap-2">
                        <Tooltip
                          html={
                            <div className=" text-left flex flex-col gap-1">
                              <p className="">{t('PendingDisputesPreCon')}</p>
                            </div>
                          }
                          position="top"
                          arrow={false}
                          animation="shift"
                          size="small"
                          {...({ children: <i className="ph text-status-green border-status-green border ph-plus h-fit text-xs p-0.5" style={{ borderRadius: '50%' }}></i> } as any)} // Type assertion to bypass TS errors
                        >
                          <span className="px-2 py-1 bg-org-orange text-white rounded-full text-xs">
                            P: {profile.Organisation.preconciliationPending}
                          </span>
                        </Tooltip>
                        <Tooltip
                          html={
                            <div className=" text-left flex flex-col gap-1">
                              <p className="">{t('PendingDisputesCon')}</p>
                            </div>
                          }
                          position="top"
                          arrow={false}
                          animation="shift"
                          size="small"
                          {...({ children: <i className="ph text-status-green border-status-green border ph-plus h-fit text-xs p-0.5" style={{ borderRadius: '50%' }}></i> } as any)} // Type assertion to bypass TS errors
                        >
                          <span className="px-2 py-1 bg-org-green text-white rounded-full text-xs">
                            C: {profile.Organisation.conciliationPending}
                          </span>
                        </Tooltip>
                        <Tooltip
                          html={
                            <div className=" text-left flex flex-col gap-1">
                              <p className="">{t('PendingDisputesArb')}</p>
                            </div>
                          }
                          position="top"
                          arrow={false}
                          animation="shift"
                          size="small"
                          {...({ children: <i className="ph text-status-green border-status-green border ph-plus h-fit text-xs p-0.5" style={{ borderRadius: '50%' }}></i> } as any)} // Type assertion to bypass TS errors
                        >
                          <span className="px-2 py-1 bg-org-purple text-white rounded-full text-xs">
                            A: {profile.Organisation.arbitrationPending}
                          </span>
                        </Tooltip>

                      </div>
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex justify-end">
                        <i className="ph ph-caret-right w-5 h-5 text-gray-400" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntermediaryMultipleOrgSelection;
